import { Directive, TemplateRef, ViewContainerRef, OnInit, OnDestroy, Input } from '@angular/core';
import { GlobalRoleType, UserVM } from 'src/app/app.generated';
import { UserInfoService } from 'src/app/services';

import * as fromAppState from '../../state';
import { Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { takeUntil, filter, tap } from 'rxjs/operators';

@Directive({ selector: '[appShowIfRole]' })
export class ShowIfRoleDirective implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();
  private globalRole: GlobalRoleType;

  @Input()
  set appShowIfRole(globalRole: GlobalRoleType ) {
    this.globalRole = globalRole;
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store<fromAppState.AppState>,
  ) { }

  ngOnInit() {
    this.store
    .pipe(
      takeUntil(this.unsubscribe$),
      select(fromAppState.getCurrentUser),
      filter(u => !!u),
      tap(u => this.showIfRole(u)))
    .subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private showIfRole(u: UserVM) {
    if (UserInfoService.hasRole(u, this.globalRole)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
