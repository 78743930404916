import { createSelector } from '@ngrx/store';

import * as fromAdminState from '../reducers';

export const getCurrentUserState = createSelector(
  fromAdminState.getAppState,
  state => state.currentUser
);

export const getCurrentUserEntities = createSelector(
  getCurrentUserState,
  state => state.entities
);

export const getCurrentUser = createSelector(
  getCurrentUserEntities,
  state => !!state && state[1] ? state[1] : null,
);

export const getCurrentUserIsLoading = createSelector(
  getCurrentUserEntities,
  state => !!state && state[1] && state[1].isLoading,
);

export const getCurrentUserHasLoaded = createSelector(
  getCurrentUserEntities,
  state => !!state && state[1] && state[1].hasLoaded,
);

export const getCurrentUserLoadError = createSelector(
  getCurrentUserEntities,
  state => !!state && state[1] && state[1].loadError,
);
