import { Action } from '@ngrx/store';

export enum LoadingBarActionTypes {
  StartLoading = '[Loading Bar] Start Loading',
  StopLoading = '[Loading Bar] Stop Loading'
}

export class StartLoading implements Action {
  readonly type = LoadingBarActionTypes.StartLoading;
}
export class StopLoading implements Action {
  readonly type = LoadingBarActionTypes.StopLoading;
}

export type LoadingBarActions =
  | StartLoading
  | StopLoading;
