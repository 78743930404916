import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import * as LoadingBarActions from '../actions/loading-bar.actions';
import { LoadingBarService } from '@ngx-loading-bar/core';

@Injectable()
export class LoadingBarEffects {
  constructor(
    private actions$: Actions,
    private service: LoadingBarService,
  ) {}

  startLoading$ = createEffect(() => this.actions$.pipe(
    ofType(LoadingBarActions.LoadingBarActionTypes.StartLoading),
    tap(() => this.service.start())
  ), { dispatch: false });

  stopLoading$ = createEffect(() => this.actions$.pipe(
    ofType(LoadingBarActions.LoadingBarActionTypes.StopLoading),
    tap(() => this.service.stop())
  ), { dispatch: false });
}
