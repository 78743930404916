import { createFeatureSelector, ActionReducerMap } from "@ngrx/store";
import {
  RouterReducerState,
  routerReducer,
} from "@ngrx/router-store";

import * as fromEquipmentSummaries from "./equipmentsummaries.reducer";
import * as fromConfirmDialog from "./confirmdialog.reducer";
import * as fromCurrentUser from "./currentuser.reducer";
import * as fromAppSettings from "./appsettings.reducer";

export interface AppState {
  router: RouterReducerState<any>;
  equipments: fromEquipmentSummaries.EquipmentSummariesState;
  confirmDialog: fromConfirmDialog.ConfirmDialogState;
  currentUser: fromCurrentUser.CurrentUserState;
  appSettings: fromAppSettings.AppSettingsState;
}

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  equipments: fromEquipmentSummaries.reducer,
  confirmDialog: fromConfirmDialog.reducer,
  currentUser: fromCurrentUser.reducer,
  appSettings: fromAppSettings.reducer,
};

export const getAppState = createFeatureSelector<AppState>("appState");

export const getRouterState = createFeatureSelector<RouterReducerState<any>>("router");
