import { Pipe, PipeTransform, Injectable } from '@angular/core';
import * as fromServices from 'src/app/services';

@Injectable()
@Pipe({
  name: 'utcToBrowserDate',
  pure: true
})
export class UtcToBrowserDate implements PipeTransform {
  constructor(
    private service: fromServices.DateConversionService) {
  }

  transform(value: Date): Date {
    return this.service.transformUtcToBrowser(value);
  }
}
