import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNow, format as formatDate, differenceInWeeks } from 'date-fns';

@Pipe({
  name: 'relativeDate',
  pure: true
})
export class RelativeDatePipe implements PipeTransform {
  transform(value: any, format: string): any {
    if (differenceInWeeks(new Date(), new Date(value)) < 2) {
      return formatDistanceToNow(new Date(value), { addSuffix: true });
    } else {
      return formatDate(new Date(value), format);
    }
  }
}
