import { Action } from '@ngrx/store';
import * as fromGenerated from 'src/app/app.generated';

export enum CurrentUserActionTypes {
  ReloadCurrentUser = '[CurrentUser Page] Reload CurrentUser',
  LoadCurrentUser = '[CurrentUser Page] Load CurrentUser',
  LoadCurrentUserSuccess = '[CurrentUser Page] Load CurrentUser Success',
  LoadCurrentUserFromStore = '[CurrentUser Page] Load CurrentUser From Store',
  LoadCurrentUserFail = '[CurrentUser Page] Load CurrentUser Fail'
}

export class ReloadCurrentUser implements Action {
  readonly type = CurrentUserActionTypes.ReloadCurrentUser;
}
export class LoadCurrentUser implements Action {
  readonly type = CurrentUserActionTypes.LoadCurrentUser;
}
export class LoadCurrentUserSuccess implements Action {
  readonly type = CurrentUserActionTypes.LoadCurrentUserSuccess;
  constructor(public payload: fromGenerated.UserVM) {}
}
export class LoadCurrentUserFromStore implements Action {
  readonly type = CurrentUserActionTypes.LoadCurrentUserFromStore;
}
export class LoadCurrentUserFail implements Action {
  readonly type = CurrentUserActionTypes.LoadCurrentUserFail;
  constructor(public payload: any) {}
}

export type CurrentUserActions =
  | ReloadCurrentUser
  | LoadCurrentUser
  | LoadCurrentUserSuccess
  | LoadCurrentUserFromStore
  | LoadCurrentUserFail
;
