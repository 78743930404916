import { Directive, TemplateRef, ViewContainerRef, Input, OnInit, OnDestroy } from '@angular/core';
import { UserVM } from 'src/app/app.generated';
import { UserInfoService } from 'src/app/services';

import * as fromAppState from '../../state';
import { Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { takeUntil, filter, tap } from 'rxjs/operators';

@Directive({ selector: '[appShowIfAdmin]' })
export class ShowIfAdminDirective implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store<fromAppState.AppState>,
  ) { }

  ngOnInit() {
    this.store
    .pipe(
      takeUntil(this.unsubscribe$),
      select(fromAppState.getCurrentUser),
      filter(u => !!u),
      tap(u => this.appShowIfAdmin(u)))
    .subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private appShowIfAdmin(u: UserVM) {
    if (UserInfoService.isAdmin(u)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
