import * as fromConfirmDialog from '../actions/confirmdialog.actions';
import * as fromAppModels from 'src/app/models';

export interface ConfirmDialogState {
  correlationId: string;
  result: fromAppModels.ConfirmDialogResult;
}

export const initialState: ConfirmDialogState = {
  correlationId: null,
  result: fromAppModels.ConfirmDialogResult.None,
};

export function reducer(state = initialState, action: fromConfirmDialog.ConfirmDialogActions): ConfirmDialogState {
  switch (action.type) {
    case fromConfirmDialog.ConfirmDialogActionTypes.ShowConfirmDialog: {
      return {
        correlationId: action.correlationId,
        result: fromAppModels.ConfirmDialogResult.None,
      };
    }

    case fromConfirmDialog.ConfirmDialogActionTypes.ConfirmDialogOK: {
      return {
        ...state,
        result: fromAppModels.ConfirmDialogResult.OK,
      };
    }

    case fromConfirmDialog.ConfirmDialogActionTypes.ConfirmDialogCancel: {
      return {
        ...state,
        result: fromAppModels.ConfirmDialogResult.Cancel,
      };
    }

    default: {
      return state;
    }
  }
}
