import { createSelector } from '@ngrx/store';

import * as fromAppState from '../reducers';
import * as fromAppModels from 'src/app/models';

export const getConfirmDialogState = createSelector(
  fromAppState.getAppState,
  state => state.confirmDialog
);

export const getCorrelationId = createSelector(
  getConfirmDialogState,
  state => state.correlationId,
);

export const getConfirmDialogResult = createSelector(
  getConfirmDialogState,
  state => state.result,
);

export const getConfirmDialogResultForCorrelationId = (correlationId: string) => createSelector(
  getCorrelationId,
  getConfirmDialogState,
  (cid, state) => cid === correlationId ? state.result : fromAppModels.ConfirmDialogResult.None,
);
