import {
  Input,
  Directive,
  OnChanges
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDisableControl]'
})
export class DisableControlDirective implements OnChanges {

  @Input()
  appDisableControl;

  ngOnChanges(changes) {
    if (changes['opDisabled']) {
      const action = this.appDisableControl ? 'disable' : 'enable';
      this.ngControl.control[action]();
    }
  }

  constructor(private ngControl: NgControl) {}
}
