import { createSelector } from '@ngrx/store';

import * as fromAppState from '../reducers';

export const getAppSettingsState = createSelector(
  fromAppState.getAppState,
  state => state.appSettings
);

export const getAppSettingsEntities = createSelector(
  getAppSettingsState,
  state => state.entities
);

export const getAppSettings = createSelector(
  getAppSettingsEntities,
  state => !!state && state[1] ? state[1] : { },
);

export const getAppSettingsIsLoading = createSelector(
  getAppSettingsEntities,
  state => !!state && state[1] && state[1].isLoading,
);

export const getAppSettingsHasLoaded = createSelector(
  getAppSettingsEntities,
  state => !!state && state[1] && state[1].hasLoaded,
);

export const getAppSettingsLoadError = createSelector(
  getAppSettingsEntities,
  state => !!state && state[1] && state[1].loadError,
);
