import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { ofType } from '@ngrx/effects';
import { tap, map, } from 'rxjs/operators';
import { MessageService } from 'primeng/api';

import * as toastActions from '../actions/toast.actions';
import * as fromModels from '../../models';

@Injectable()
export class ToastEffects {
  constructor(
    private actions$: Actions,
    private service: MessageService,
  ) {}

  showToast$ = createEffect(() => this.actions$.pipe(
    ofType(toastActions.ToastActionTypes.ShowToast),
    map(a => <toastActions.ShowToast>a),
    tap(a => this.service.add({
      severity: a.severity === fromModels.ToastSeverity.Error ? 'error' : 'success',
      summary: a.title,
      detail: a.message,
    })),
    map(() => []),
  ), {dispatch: false});
}
