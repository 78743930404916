import { Injectable } from '@angular/core';
import { addMinutes } from 'date-fns';

@Injectable()
export class DateConversionService {
  transformToAbsolute(value: Date | string): Date {
    // this takes in a date string in the browser's TZ and converts it to UTC
    const date = new Date(value);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const milliseconds = date.getMilliseconds();

    // Create a new Date object in UTC
    const utcDate = new Date(Date.UTC(year, month, day, hours, minutes, seconds, milliseconds));
    return utcDate;
  }

  // This is no longer needed - datestime is now stored without offset
  transformUtcToBrowser(value: Date): Date {
    // this takes in a date string in UTC and converts it to the browser's TZ
    // const offset = value.getTimezoneOffset();
    // return addMinutes(value, -offset);
    return value;
  }
}
