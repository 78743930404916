import { Action } from '@ngrx/store';

export enum BrowserActionTypes {
  ScrollToTop = '[Browser] Scroll To Top',
  OpenUrl = '[Browser] Open Url',
  OpenBlob = '[Browser] Open Downloaded Fileblob',
  ReloadCurrentPage = '[Browser] Reload Current Page',
  DownloadFile = '[Browser] Download File',
}

export class ScrollToTop implements Action {
  readonly type = BrowserActionTypes.ScrollToTop;
}

export class OpenUrl implements Action {
  readonly type = BrowserActionTypes.OpenUrl;
  constructor(public url: string) { }
}

export class DownloadFile implements Action {
  readonly type = BrowserActionTypes.DownloadFile;
  constructor(public uri: string) { }
}

export class OpenBlob implements Action {
  readonly type = BrowserActionTypes.OpenBlob;
  constructor(public filename: string, public blob: Blob) { }
}

export class ReloadCurrentPage implements Action {
  readonly type = BrowserActionTypes.ReloadCurrentPage;
  constructor() { }
}

export type BrowserActions =
  | ScrollToTop
  | OpenUrl
  | OpenBlob
  | DownloadFile
  | ReloadCurrentPage
;
