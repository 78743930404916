import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as fromModels from '../../models';
import * as fromAppSettings from '../actions/appsettings.actions';

export interface AppSettingsState extends EntityState<fromModels.AppSettingsEx> { }

const adapter: EntityAdapter<fromModels.AppSettingsEx> = createEntityAdapter<fromModels.AppSettingsEx>();

export const intitalState: AppSettingsState = adapter.getInitialState();

export function reducer(state = intitalState, action: fromAppSettings.AppSettingsActions): AppSettingsState {
  switch (action.type) {
    case fromAppSettings.AppSettingsActionTypes.LoadAppSettings: {
      const entity = <fromModels.AppSettingsEx>{
        id: 1,
        hasLoaded: false,
        isLoading: true,
        loadError: null,
      };

      return adapter.upsertOne(entity, state);
    }

    case fromAppSettings.AppSettingsActionTypes.LoadAppSettingsSuccess: {
      const entity = <fromModels.AppSettingsEx>{
        ...action.payload,
        id: 1,
        hasLoaded: true,
        isLoading: false,
        loadError: null,
      };

      return adapter.upsertOne(entity, state);
    }

    case fromAppSettings.AppSettingsActionTypes.LoadAppSettingsFromStore: {
      const entity = <fromModels.AppSettingsEx>{
        ...state.entities[1],
        id: 1,
        hasLoaded: true,
        isLoading: false,
        loadError: null,
      };

      return adapter.upsertOne(entity, state);
    }

    case fromAppSettings.AppSettingsActionTypes.LoadAppSettingsFail: {
      const entity = <fromModels.AppSettingsEx>{
        ...state.entities[1],
        id: 1,
        hasLoaded: false,
        isLoading: false,
        loadError: action.payload,
      };

      return adapter.upsertOne(entity, state);
    }

    default: {
      return state;
    }
  }
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors();
