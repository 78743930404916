import { Injectable } from '@angular/core';

@Injectable()
export class JavaScriptUtilsService {
  static cloneObjectFistLevel<TIn, TOut>(o: TIn): TOut {
    const oOut = {};

    Object.keys(o).forEach(n => {
      oOut[n] = o[n];
    });

    return <TOut>oOut;
  }
}
