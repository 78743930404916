import { Pipe, PipeTransform } from '@angular/core';
import { MathService } from '../../services/math.service';

@Pipe({
  name: 'roundToInteger',
  pure: true
})
export class RoundToInteger implements PipeTransform {
  transform(value: any): any {
    return MathService.RoundToInteger(value);
  }
}
