import { Injectable } from '@angular/core';

import * as fromGenerated from '../app.generated';
import * as fromAppModels from '../models';

@Injectable({
  providedIn: 'root'
})
export class EnumValueDescriptorService {
  private static landUseDescriptors: fromAppModels.EnumValueDescriptor<
    fromGenerated.LandUse
  >[] = [
    {
      name: 'Single residential',
      value: 0,
      enum: fromGenerated.LandUse.SingleResidential,
      data: { short: '1RES' }
    },
    {
      name: 'Multi residential',
      value: 1,
      enum: fromGenerated.LandUse.MultiResidential,
      data: { short: 'nRES' }
    },
    {
      name: 'Residential',
      value: 2,
      enum: fromGenerated.LandUse.Residential,
      data: { short: 'RES' }
    },
    {
      name: 'Commercial',
      value: 3,
      enum: fromGenerated.LandUse.Commercial,
      data: { short: 'COM' }
    },
    {
      name: 'Industrial',
      value: 4,
      enum: fromGenerated.LandUse.Industrial,
      data: { short: 'IND' }
    },
    {
      name: 'Educational',
      value: 5,
      enum: fromGenerated.LandUse.Educational,
      data: { short: 'EDU' }
    },
    {
      name: 'Places of worship',
      value: 6,
      enum: fromGenerated.LandUse.PlacesOfWorship,
      data: { short: 'PoW' }
    },
    {
      name: 'Active Recreation',
      value: 8,
      enum: fromGenerated.LandUse.ActiveRecreation,
      data: { short: 'aREC' }
    },
    {
      name: 'Passive Recreation',
      value: 9,
      enum: fromGenerated.LandUse.PassiveRecreation,
      data: { short: 'pREC' }
    },
    {
      name: 'Other sensitive receivers',
      value: 7,
      enum: fromGenerated.LandUse.OtherSensitiveReceivers,
      data: { short: 'NONE' }
    }
    // TODO: hospital/health = HOS
  ];

  private static AssessmentStatusUpdateDescriptors: fromAppModels.EnumValueDescriptor<
  fromGenerated.AssessmentStatusUpdate
>[] = [
  {
    name: 'Submit',
    value: 0,
    enum: fromGenerated.AssessmentStatusUpdate.Submit,
    data: { },
  },
  {
    name: 'Reject',
    value: 1,
    enum: fromGenerated.AssessmentStatusUpdate.Reject,
    data: { colorClass: 'text-error', icon: 'fa fa-times', name: 'rejected'},
  },
  {
    name: 'Approve',
    value: 2,
    enum: fromGenerated.AssessmentStatusUpdate.Approve,
    data: {colorClass: 'text-success', icon: 'fa fa-check', name: 'Approved' },
  },
  {
    name: 'Skip',
    value: 3,
    enum: fromGenerated.AssessmentStatusUpdate.Skip,
    data: { colorClass: 'text-warning', icon: 'fa fa-exclamation-triangle', name: 'Skipped'},
  },
];

  private static assessmentStatusDescriptors: fromAppModels.EnumValueDescriptor<
    fromGenerated.AssessmentStatus
  >[] = [
    {
      name: 'Created',
      value: 0,
      enum: fromGenerated.AssessmentStatus.Created,
      data: { },
    },
    {
      name: 'In progress',
      value: 1,
      enum: fromGenerated.AssessmentStatus.InProgress,
      data: { },
    },
    {
      name: 'Submitted',
      value: 2,
      enum: fromGenerated.AssessmentStatus.Submitted,
      data: { colorClass: 'text-warning', icon: 'fa fa-clock-o', },
    },
    {
      name: 'Approved',
      value: 3,
      enum: fromGenerated.AssessmentStatus.Approved,
      data: { colorClass: 'text-success', icon: 'fa fa-check', },
    },
  ];

  private static globalRoleTypeDescriptors: fromAppModels.EnumValueDescriptor<
    fromGenerated.GlobalRoleType
  >[] = [
    {
      name: 'Administrator',
      value: 0,
      enum: fromGenerated.GlobalRoleType.Administrator,
      data: { },
    },
    {
      name: 'Minor Works User',
      value: 1,
      enum: fromGenerated.GlobalRoleType.MinorWorksUser,
      data: { },
    },
  ];

  private static projectRoleTypeDescriptors: fromAppModels.EnumValueDescriptor<
    fromGenerated.ProjectRoleType
  >[] = [
    {
      name: 'Assessment manager',
      value: 0,
      enum: fromGenerated.ProjectRoleType.AssessmentManager,
      data: { },
    },
    {
      name: 'Assessment coordinator',
      value: 1,
      enum: fromGenerated.ProjectRoleType.AssessmentCoordinator,
      data: { },
    },
  ];

  private static projectTypeDescriptors: fromAppModels.EnumValueDescriptor<
    fromGenerated.ProjectType
  >[] = [
    {
      name: 'Standard',
      value: 0,
      enum: fromGenerated.ProjectType.Standard,
      data: {
        pathName: 'projects'
      },
    },
    {
      name: 'MinorWorks',
      value: 1,
      enum: fromGenerated.ProjectType.MinorWorks,
      data: {
        pathName: 'minor-works'
      },
    },
    {
      name: 'GroundBorne',
      value: 2,
      enum: fromGenerated.ProjectType.GroundBorne,
      data: {
        pathName: 'ground-borne'
      },
    },
  ];

  private static modelingDataFileTypeDescriptors: fromAppModels.EnumValueDescriptor<
    fromGenerated.ModelingDataFileType
  >[] = [
    {
      name: 'Model results',
      value: 0,
      enum: fromGenerated.ModelingDataFileType.ModelResults,
      data: { },
    },
    {
      name: 'Receivers',
      value: 1,
      enum: fromGenerated.ModelingDataFileType.Receivers,
      data: { },
    },
    {
      name: 'Source locations',
      value: 2,
      enum: fromGenerated.ModelingDataFileType.SourceLocations,
      data: { },
    },
  ];

  private static modelingDataStatusDescriptors: fromAppModels.EnumValueDescriptor<
    fromGenerated.ModelingDataState
  >[] = [
    {
      name: 'None',
      value: 0,
      enum: fromGenerated.ModelingDataState.None,
      data: { },
    },
    {
      name: 'Uploaded',
      value: 1,
      enum: fromGenerated.ModelingDataState.Uploaded,
      data: { },
    },
    {
      name: 'Validating',
      value: 2,
      enum: fromGenerated.ModelingDataState.Validating,
      data: { },
    },
    {
      name: 'Validated',
      value: 3,
      enum: fromGenerated.ModelingDataState.Validated,
      data: { },
    },
    {
      name: 'Importing',
      value: 4,
      enum: fromGenerated.ModelingDataState.Importing,
      data: { },
    },
    {
      name: 'Imported',
      value: 5,
      enum: fromGenerated.ModelingDataState.Imported,
      data: { },
    },
    {
      name: 'Error importing',
      value: 6,
      enum: fromGenerated.ModelingDataState.ErrorImporting,
      data: { },
    },
  ];

  private static preferredNoiseLevelMeasurementDescriptors: fromAppModels.EnumValueDescriptor<
    fromGenerated.PreferredNoiseLevelMeasurement
  >[] = [
    {
      name: 'NML',
      value: 0,
      enum: fromGenerated.PreferredNoiseLevelMeasurement.NML,
      data: { },
    },
    {
      name: 'RBL',
      value: 1,
      enum: fromGenerated.PreferredNoiseLevelMeasurement.RBL,
      data: { },
    },
    ];

  private static vibrationStandardDescriptors: fromAppModels.EnumValueDescriptor<
    fromGenerated.VibrationStandardType
  >[] = [
    {
      name: 'None',
      value: 0,
      enum: fromGenerated.VibrationStandardType.None,
      data: {}
    },
    {
      name: 'British Standard',
      value: 1,
      enum: fromGenerated.VibrationStandardType.BritishStandard,
      data: {}
    },
    {
      name: 'DIN Resi',
      value: 2,
      enum: fromGenerated.VibrationStandardType.DINResi,
      data: {}
    }
  ];

  private static vibrationImpactClassificationDescriptors: fromAppModels.EnumValueDescriptor<
    fromGenerated.VibrationImpactClassification
  >[] = [
    {
      name: 'None',
      value: 0,
      enum: fromGenerated.VibrationImpactClassification.None,
      data: { },
    },
    {
      name: 'Human Comfort',
      value: 1,
      enum: fromGenerated.VibrationImpactClassification.HumanComfort,
      data: { },
    },
    {
      name: 'Cosmetic',
      value: 2,
      enum: fromGenerated.VibrationImpactClassification.Cosmetic,
      data: { },
    },
    {
      name: 'Heritage',
      value: 3,
      enum: fromGenerated.VibrationImpactClassification.Heritage,
      data: { },
    },
  ];

  private static groundBorneVibrationImpactClassificationDescriptors: fromAppModels.EnumValueDescriptor<
    fromGenerated.VibrationImpactClassification
  >[] = [
    {
      name: 'None',
      value: 0,
      enum: fromGenerated.VibrationImpactClassification.None,
      data: { },
    },
    {
      name: 'Exceeds',
      value: 4,
      enum: fromGenerated.VibrationImpactClassification.Exceeds,
      data: { },
    },
  ];

  static getLandUseDescriptors = () =>
    EnumValueDescriptorService.landUseDescriptors

  static getLandUseDescriptorsMap(): Map<
    fromGenerated.LandUse,
    fromAppModels.EnumValueDescriptor<fromGenerated.LandUse>
  > {
    const map = new Map<
      fromGenerated.LandUse,
      fromAppModels.EnumValueDescriptor<fromGenerated.LandUse>
    >();

    EnumValueDescriptorService.getLandUseDescriptors().forEach(desc =>
      map.set(desc.enum, desc)
    );

    return map;
  }

  static getAssessmentStatusDescriptors = () =>
    EnumValueDescriptorService.assessmentStatusDescriptors

  static getAssessmentStatusDescriptorsMap(): Map<
    fromGenerated.AssessmentStatus,
    fromAppModels.EnumValueDescriptor<fromGenerated.AssessmentStatus>
  > {
    const map = new Map<
      fromGenerated.AssessmentStatus,
      fromAppModels.EnumValueDescriptor<fromGenerated.AssessmentStatus>
    >();

    EnumValueDescriptorService.getAssessmentStatusDescriptors().forEach(desc =>
      map.set(desc.enum, desc)
    );

    return map;
  }

  static getAssessmentStatusUpdateDescriptors = () =>
    EnumValueDescriptorService.AssessmentStatusUpdateDescriptors

  static getAssessmentStatusUpdateDescriptorsMap(): Map<
  fromGenerated.AssessmentStatusUpdate,
  fromAppModels.EnumValueDescriptor<fromGenerated.AssessmentStatusUpdate>
> {
  const map = new Map<
    fromGenerated.AssessmentStatusUpdate,
    fromAppModels.EnumValueDescriptor<fromGenerated.AssessmentStatusUpdate>
  >();

  EnumValueDescriptorService.getAssessmentStatusUpdateDescriptors().forEach(desc =>
    map.set(desc.enum, desc)
  );

  return map;
}

  static getGlobalRoleTypeDescriptors = () =>
    EnumValueDescriptorService.globalRoleTypeDescriptors

  static getGlobalRoleTypeDescriptorsMap(): Map<
    fromGenerated.GlobalRoleType,
    fromAppModels.EnumValueDescriptor<fromGenerated.GlobalRoleType>
  > {
    const map = new Map<
      fromGenerated.GlobalRoleType,
      fromAppModels.EnumValueDescriptor<fromGenerated.GlobalRoleType>
    >();

    EnumValueDescriptorService.getGlobalRoleTypeDescriptors().forEach(desc =>
      map.set(desc.enum, desc)
    );

    return map;
  }

  static getProjectRoleTypeDescriptors = () =>
    EnumValueDescriptorService.projectRoleTypeDescriptors

  static getProjectRoleTypeDescriptorsMap(): Map<
    fromGenerated.ProjectRoleType,
    fromAppModels.EnumValueDescriptor<fromGenerated.ProjectRoleType>
  > {
    const map = new Map<
      fromGenerated.ProjectRoleType,
      fromAppModels.EnumValueDescriptor<fromGenerated.ProjectRoleType>
    >();

    EnumValueDescriptorService.getProjectRoleTypeDescriptors().forEach(desc =>
      map.set(desc.enum, desc)
    );

    return map;
  }

  static getProjectTypeDescriptors = () =>
    EnumValueDescriptorService.projectTypeDescriptors

  static getProjectTypeDescriptorsMap(): Map<
    fromGenerated.ProjectType,
    fromAppModels.EnumValueDescriptor<fromGenerated.ProjectType>
  > {
    const map = new Map<
      fromGenerated.ProjectType,
      fromAppModels.EnumValueDescriptor<fromGenerated.ProjectType>
    >();

    EnumValueDescriptorService.getProjectTypeDescriptors().forEach(desc =>
      map.set(desc.enum, desc)
    );

    return map;
  }

  static getModelingDataFileTypeDescriptors = () =>
    EnumValueDescriptorService.modelingDataFileTypeDescriptors

  static getModelingDataFileTypeDescriptorsMap(): Map<
    fromGenerated.ModelingDataFileType,
    fromAppModels.EnumValueDescriptor<fromGenerated.ModelingDataFileType>
  > {
    const map = new Map<
      fromGenerated.ModelingDataFileType,
      fromAppModels.EnumValueDescriptor<fromGenerated.ModelingDataFileType>
    >();

    EnumValueDescriptorService.getModelingDataFileTypeDescriptors().forEach(desc =>
      map.set(desc.enum, desc)
    );

    return map;
  }

  static getModelingDataStateDescriptors = () =>
    EnumValueDescriptorService.modelingDataStatusDescriptors

  static getModelingDataStateDescriptorsMap(): Map<
    fromGenerated.ModelingDataState,
    fromAppModels.EnumValueDescriptor<fromGenerated.ModelingDataState>
  > {
    const map = new Map<
      fromGenerated.ModelingDataState,
      fromAppModels.EnumValueDescriptor<fromGenerated.ModelingDataState>
    >();

    EnumValueDescriptorService.getModelingDataStateDescriptors().forEach(desc =>
      map.set(desc.enum, desc)
    );

    return map;
  }

  static getVibrationStandardDescriptors = () =>
    EnumValueDescriptorService.vibrationStandardDescriptors

  static getVibrationStandardDescriptorsMap(): Map<
    fromGenerated.VibrationStandardType,
    fromAppModels.EnumValueDescriptor<fromGenerated.VibrationStandardType>
  > {
    const map = new Map<
      fromGenerated.VibrationStandardType,
      fromAppModels.EnumValueDescriptor<fromGenerated.VibrationStandardType>
    >();

    EnumValueDescriptorService.getVibrationStandardDescriptors().forEach(desc =>
      map.set(desc.enum, desc)
    );

    return map;
  }

  static getPreferredNoiseLevelMeasurementDescriptors = () =>
    EnumValueDescriptorService.preferredNoiseLevelMeasurementDescriptors

  static getPreferredNoiseLevelMeasurementDescriptorsMap(): Map<
    fromGenerated.PreferredNoiseLevelMeasurement,
    fromAppModels.EnumValueDescriptor<fromGenerated.PreferredNoiseLevelMeasurement>
  > {
    const map = new Map<
      fromGenerated.PreferredNoiseLevelMeasurement,
      fromAppModels.EnumValueDescriptor<fromGenerated.PreferredNoiseLevelMeasurement>
    >();

    EnumValueDescriptorService.getPreferredNoiseLevelMeasurementDescriptors().forEach(desc =>
      map.set(desc.enum, desc)
    );

    return map;
  }

  static getVibrationImpactClassificationDescriptors = (projectType: fromGenerated.ProjectType = null) => {
    if (projectType === fromGenerated.ProjectType.GroundBorne) {
        return EnumValueDescriptorService.getGroundBorneVibrationImpactClassificationDescriptors();
    }

    return EnumValueDescriptorService.vibrationImpactClassificationDescriptors;
  }

  static getVibrationImpactClassificationDescriptorsMap(): Map<
    fromGenerated.VibrationImpactClassification,
    fromAppModels.EnumValueDescriptor<fromGenerated.VibrationImpactClassification>
  > {
    const map = new Map<
      fromGenerated.VibrationImpactClassification,
      fromAppModels.EnumValueDescriptor<fromGenerated.VibrationImpactClassification>
    >();

    EnumValueDescriptorService.getVibrationImpactClassificationDescriptors().forEach(desc =>
      map.set(desc.enum, desc)
    );

    return map;
  }

  static getGroundBorneVibrationImpactClassificationDescriptors = () =>
    EnumValueDescriptorService.groundBorneVibrationImpactClassificationDescriptors

  static getGroundBorneVibrationImpactClassificationDescriptorsMap(): Map<
    fromGenerated.VibrationImpactClassification,
    fromAppModels.EnumValueDescriptor<fromGenerated.VibrationImpactClassification>
  > {
    const map = new Map<
      fromGenerated.VibrationImpactClassification,
      fromAppModels.EnumValueDescriptor<fromGenerated.VibrationImpactClassification>
    >();

    EnumValueDescriptorService.getGroundBorneVibrationImpactClassificationDescriptors().forEach(desc =>
      map.set(desc.enum, desc)
    );

    return map;
  }
}
