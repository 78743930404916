import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import * as BrowserActions from '../actions/browser.actions';

@Injectable()
export class BrowserEffects {
  constructor(private actions$: Actions) {}

  startLoading$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BrowserActions.BrowserActionTypes.ScrollToTop),
        tap(() => window.scrollTo(0, 0))
      ),
    { dispatch: false }
  );

  openUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BrowserActions.BrowserActionTypes.OpenUrl),
        tap((a: BrowserActions.OpenUrl) => window.open(a.url, '_blank'))
      ),
    { dispatch: false }
  );

  downloadFile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BrowserActions.BrowserActionTypes.DownloadFile),
        tap((a: BrowserActions.DownloadFile) => {
          saveAs(a.uri);
        })
      ),
    { dispatch: false }
  );

  reloadCurrentPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BrowserActions.BrowserActionTypes.ReloadCurrentPage),
        tap(() => window.location.reload())
      ),
    { dispatch: false }
  );

  openFileBlob$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BrowserActions.BrowserActionTypes.OpenBlob),
        tap((a: BrowserActions.OpenBlob) => {
          saveAs(a.blob, a.filename);
        })
      ),
    { dispatch: false }
  );
}
