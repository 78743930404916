import { createSelector } from '@ngrx/store';

import * as fromAppState from '../reducers';
import * as fromEquipmentSummariesReducers from '../reducers/equipmentsummaries.reducer';

export const getEquipmentSummariesState = createSelector(
  fromAppState.getAppState,
  state => state.equipments
);

export const getEquipmentSummaries = createSelector(
  getEquipmentSummariesState,
  fromEquipmentSummariesReducers.selectAll
);

export const getIsLoading = createSelector(
  getEquipmentSummariesState,
  state => state.isLoading,
);

export const getHasLoaded = createSelector(
  getEquipmentSummariesState,
  state => state.hasLoaded,
);

export const getLoadError = createSelector(
  getEquipmentSummariesState,
  state => state.loadError,
);
