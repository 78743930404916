import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable()
export class TokenHttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const at = localStorage.getItem('id_token');
    if (at) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${at}`
        }
      });
    }

    return next.handle(request);
  }
}
