import { Injectable } from '@angular/core';
import * as fromGenerated from '../app.generated';
import { EnumValueDescriptorService } from './enumvalue-descriptor.service';

@Injectable()
export class UserInfoService {
  static isAdmin(u: fromGenerated.UserVM): boolean {
    return this.hasRole(u, fromGenerated.GlobalRoleType.Administrator);
  }

  static hasRole(user: fromGenerated.UserVM, role: fromGenerated.GlobalRoleType): boolean {
    return user &&
      user.globalRoles &&
      user.globalRoles.length !== 0 &&
      user.globalRoles.some(u => u.roleType === role || u.roleType === fromGenerated.GlobalRoleType.Administrator);
  }

  static hasProjectRole(u: fromGenerated.UserVM, role: fromGenerated.ProjectRoleType): boolean {
    return u
      && u.projectRoles
      && !!u.projectRoles.find(gr => gr.roleType === role);
  }

  static getUserRoleName(u: fromGenerated.UserVM): string {
    if (this.isAdmin(u)) {
      return EnumValueDescriptorService
        .getGlobalRoleTypeDescriptorsMap()
        .get(fromGenerated.GlobalRoleType.Administrator)
        .name;
    } else if (this.hasProjectRole(u, fromGenerated.ProjectRoleType.AssessmentManager)) {
      return EnumValueDescriptorService
        .getProjectRoleTypeDescriptorsMap()
        .get(fromGenerated.ProjectRoleType.AssessmentManager)
        .name;
    } else if (this.hasProjectRole(u, fromGenerated.ProjectRoleType.AssessmentCoordinator)) {
      return EnumValueDescriptorService
        .getProjectRoleTypeDescriptorsMap()
        .get(fromGenerated.ProjectRoleType.AssessmentCoordinator)
        .name;
    } else {
      return 'Unknown role!';
    }
  }
}
