import { Action } from '@ngrx/store';
import * as fromModels from '../../models';

export enum AppSettingsActionTypes {
  ReloadAppSettings = '[AppSettings Page] Reload AppSettings',
  LoadAppSettings = '[AppSettings Page] Load AppSettings',
  LoadAppSettingsSuccess = '[AppSettings Page] Load AppSettings Success',
  LoadAppSettingsFromStore = '[AppSettings Page] Load AppSettings From Store',
  LoadAppSettingsFail = '[AppSettings Page] Load AppSettings Fail',
}
export class ReloadAppSettings implements Action {
  readonly type = AppSettingsActionTypes.ReloadAppSettings;
}
export class LoadAppSettings implements Action {
  readonly type = AppSettingsActionTypes.LoadAppSettings;
}
export class LoadAppSettingsSuccess implements Action {
  readonly type = AppSettingsActionTypes.LoadAppSettingsSuccess;
  constructor(public payload: fromModels.AppSettingsEx) {}
}
export class LoadAppSettingsFromStore implements Action {
  readonly type = AppSettingsActionTypes.LoadAppSettingsFromStore;
}
export class LoadAppSettingsFail implements Action {
  readonly type = AppSettingsActionTypes.LoadAppSettingsFail;
  constructor(public payload: any) {}
}

export type AppSettingsActions =
  | ReloadAppSettings
  | LoadAppSettings
  | LoadAppSettingsSuccess
  | LoadAppSettingsFromStore
  | LoadAppSettingsFail
;
