import { Action } from '@ngrx/store';

export enum ConfirmDialogActionTypes {
  ShowConfirmDialog = '[ConfirmDialog] Show ConfirmDialog',
  ConfirmDialogOK = '[ConfirmDialog] ConfirmDialog OK',
  ConfirmDialogCancel = '[ConfirmDialog] ConfirmDialog Cancel',
}
export class ShowConfirmDialog implements Action {
  readonly type = ConfirmDialogActionTypes.ShowConfirmDialog;
  constructor(public correlationId: string, public title: string, public message: string) {}
}
export class ConfirmDialogOK implements Action {
  readonly type = ConfirmDialogActionTypes.ConfirmDialogOK;
  constructor(public correlationId: string) {}
}
export class ConfirmDialogCancel implements Action {
  readonly type = ConfirmDialogActionTypes.ConfirmDialogCancel;
  constructor(public correlationId: string) {}
}

export type ConfirmDialogActions =
  | ShowConfirmDialog
  | ConfirmDialogOK
  | ConfirmDialogCancel
;
