import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { of, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as auth0 from 'auth0-js';
import { AppSettingsBootstrapService } from './appsettingsbootstrap.service';
import { Store } from '@ngrx/store';
import * as fromAppStore from '../state';

(window as any).global = window;

@Injectable()
export class AuthService {
  baseUrl: string;
  userProfile: any;
  refreshSubscription: any;

  auth0: auth0.WebAuth;

  constructor(
      private router: Router,
      private location: Location,
      private appSettingsService: AppSettingsBootstrapService,
      private store: Store<fromAppStore.AppState>,
  ) {
    this.baseUrl = window.location.origin;
    this.auth0 = new auth0.WebAuth({
      clientID: this.appSettingsService.appSettings.auth0ClientId || '',
      domain: this.appSettingsService.appSettings.auth0Domain || '',
      redirectUri: `${this.baseUrl}${this.appSettingsService.appSettings.auth0CallbackUrl || ''}`,
      responseType: 'token id_token',
      scope: 'openid profile email'
    });
  }

  public login(): void {
    if (!localStorage.getItem('redirect_url')) {
      localStorage.setItem('redirect_url', this.location.path());
    }
    this.auth0.authorize();
  }

  public handleAuthentication(): void {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
      } else if (err) {
        console.log(err);
      }

      if (this.location.path() === '/callback') {
        const urlTree = this.getRedirectLocation();
        this.router.navigateByUrl(this.router.serializeUrl(urlTree));
      }

      this.store.dispatch(new fromAppStore.LoadCurrentUser());
    });
  }

  public getProfile(cb): void {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      throw new Error('Access token must exist to fetch profile');
    }

    const self = this;
    this.auth0.client.userInfo(accessToken, (err, profile) => {
      if (profile) {
        self.userProfile = profile;
      }
      cb(err, profile);
    });
  }

  public getTokens(): any {
    return {
      access_token: localStorage.getItem('access_token'),
      id_token: localStorage.getItem('id_token'),
      expires_at: localStorage.getItem('expires_at'),
    };
  }

  private setSession(authResult): void {
    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + Date.now());

    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);

    this.scheduleRenewal();
  }

  public logout(): void {
    // Remove tokens and expiry time from localStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    this.unscheduleRenewal();

    const settings = this.appSettingsService.appSettings;
    // Go back to the home route
    window.location.href =
      `https://${settings.auth0Domain}/v2/logout?returnTo=${this.baseUrl}${settings.auth0LogoutUrl}&client_id=${settings.auth0ClientId}`;
  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at') || '{}');
    return Date.now() < expiresAt;
  }

  public renewToken() {
    this.auth0.checkSession({}, (_err, _result) => { });
  }

  public scheduleRenewal() {
    if (!this.isAuthenticated()) {
      return;
    }

    this.unscheduleRenewal();

    const expiresAt = JSON.parse(window.localStorage.getItem('expires_at'));

    const source = of(expiresAt).pipe(
      mergeMap(e => {
        const now = Date.now();

        // Use the delay in a timer to
        // run the refresh at the proper time
        return timer(Math.max(1, e - now));
      })
    );

    // Once the delay time from above is
    // reached, get a new JWT and schedule
    // additional refreshes
    this.refreshSubscription = source.subscribe(() => {
      this.renewToken();
      this.scheduleRenewal();
    });
  }

  public unscheduleRenewal() {
    if (!this.refreshSubscription) {
      return;
    }

    this.refreshSubscription.unsubscribe();
  }

  private getRedirectLocation(): UrlTree {
    let redirectUrl = localStorage.getItem('redirect_url');

    if (localStorage.getItem('redirect_url')) {
      localStorage.removeItem('redirect_url');
    }
    if (redirectUrl === '/callback') {
      redirectUrl = null;
    }
    redirectUrl = redirectUrl ?? '/projects';

    return this.router.parseUrl(redirectUrl);
  }
}
