<div class="container my-5">
  <div class="card p-3 p-xl-5">
    <div class="row align-items-center my-3">
      <div class="col-md-2 text-center">
        <span class="text-danger" style="font-size: 3rem">
          <i class="fa fa-exclamation-triangle fa-lg"></i>
        </span>
      </div>
      <div class="col">
        <h1>Operation resulted in a error.</h1>
        <h4>
          Please contact <a href="mailto:info@hutchisonweller.com">support</a> with the following
          information.
        </h4>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-2 text-right">
        <p><b>Status</b></p>
      </div>
      <div class="col-md-10"><p>{{ error.status }}</p></div>
    </div>
    <div class="row">
      <div class="col-md-2 text-right">
        <p><b>Message</b></p>
      </div>
      <div class="col-md-10"><p>{{ error.message }}</p></div>
    </div>
    <div class="row">
      <div class="col-md-2 text-right">
        <p><b>Response</b></p>
      </div>
      <div class="col-md-10"><p>{{ error.response | json }}</p></div>
    </div>
  </div>
</div>
