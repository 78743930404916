import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { ofType } from '@ngrx/effects';
import { tap, map, } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ConfirmationService } from 'primeng/api';

import * as fromStore from '..';
import * as confirmDialogActions from '../actions/confirmdialog.actions';

@Injectable()
export class ConfirmDialogEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromStore.AppState>,
    private service: ConfirmationService,
  ) {}

  showConfirmDialog$ = createEffect(() => this.actions$.pipe(
    ofType(confirmDialogActions.ConfirmDialogActionTypes.ShowConfirmDialog),
    map(a => <confirmDialogActions.ShowConfirmDialog>a),
    tap(a => this.service.confirm({
      message: a.message,
      header: a.title,
      icon: 'pi pi-question-circle',
      accept: () => {
        this.store.dispatch(new confirmDialogActions.ConfirmDialogOK(a.correlationId));
      },
      reject: () => {
        this.store.dispatch(new confirmDialogActions.ConfirmDialogCancel(a.correlationId));
      }
    })),
    map(() => []),
  ), {dispatch: false});
}
