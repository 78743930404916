import { Injectable } from '@angular/core';
import { AppSettingsService, AppSettings } from '../app.generated';

// REFER: https://blogs.msdn.microsoft.com/webdev/2017/10/27/sharing-configuration-in-asp-net-core-spa-scenarios/
@Injectable()
export class AppSettingsBootstrapService {
  public appSettings: AppSettings;

  constructor(private appSettingService: AppSettingsService) {}

  loadAppSettings() {
    return this.appSettingService
      .get()
      .toPromise()
      .then(
        as => {
          this.appSettings = as;
          console.log('AppSettings loaded:', as);
        },
        err => {
          console.error('AppSettings load error:', err);
        }
      );
  }
}
