import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

import * as fromAppModels from '../models';

@Injectable()
export class ScriptLoaderService {
  private scripts: fromAppModels.Script[] = [];

  public load(script: fromAppModels.Script): Observable<fromAppModels.Script> {
    return new Observable<fromAppModels.Script>(
      (observer: Observer<fromAppModels.Script>) => {
        const existingScript = this.scripts.find(s => s.name === script.name);

        // Complete if already loaded
        if (existingScript && existingScript.loaded) {
          observer.next(existingScript);
          observer.complete();
        } else {
          // Add the script
          this.scripts = [...this.scripts, script];

          // Load the script
          const scriptElement = document.createElement('script');
          scriptElement.type = 'text/javascript';
          scriptElement.src = script.src;
          scriptElement.setAttribute('loading', 'async')

          scriptElement.onload = () => {
            script.loaded = true;
            observer.next(script);
            observer.complete();
          };

          scriptElement.onerror = (error: any) => {
            observer.error('Couldn\'t load script ' + script.src);
          };

          document.getElementsByTagName('body')[0].appendChild(scriptElement);
        }
      }
    );
  }
}
