import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import * as fromModels from '../../models';
import * as fromGenerated from '../../app.generated';
import * as fromStore from '..';
import * as fromActions from '../actions/appsettings.actions';
import * as globalRolesSelectors from '../selectors/appsettings.selectors';

@Injectable()
export class AppSettingsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromStore.AppState>,
    private service: fromGenerated.AppSettingsService,
  ) {}

  loadAllAppSettings$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.AppSettingsActionTypes.LoadAppSettings),
    withLatestFrom(this.store.pipe(select(globalRolesSelectors.getAppSettingsHasLoaded))),
    mergeMap(([ _, hasLoaded ]): Observable<fromActions.AppSettingsActions> => {
      if (hasLoaded) {
        return of(new fromActions.LoadAppSettingsFromStore());
      } else {
        return of(new fromActions.ReloadAppSettings());
      }
    })
  ));

  reloadAllAppSettings$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.AppSettingsActionTypes.ReloadAppSettings),
    mergeMap((): Observable<fromActions.AppSettingsActions> => {
      return this.service.get().pipe(
        map((aps: fromGenerated.AppSettings) => new fromActions.LoadAppSettingsSuccess(<fromModels.AppSettingsEx>{id: 1, ...aps})),
        catchError(error => of(new fromActions.LoadAppSettingsFail(error)))
      );
    })
  ));
}
