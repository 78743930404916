import { Pipe, PipeTransform } from '@angular/core';
import { MathService } from '../../services/math.service';

@Pipe({
  name: 'roundToDecimal',
  pure: true
})
export class RoundToDecimal implements PipeTransform {
  transform(value: any): any {
    return MathService.RoundToDecimal(value);
  }
}
