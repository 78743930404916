import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import ImageResizor from 'image-resizor';

@Injectable({
  providedIn: 'root'
})
export class ImageResizeService {
  resizeImage(
    file: File,
    width: number,
    height: number,
    output: 'Blob' | 'base64'
  ): Observable<Blob | string> {
    const imageResizor = new ImageResizor(file, {
      maxHeight: height,
      maxWidth: width,
      outputType: 'image/png'
    });
    const resizor = from(imageResizor.init());

    if (output === 'Blob') {
      return resizor.pipe(switchMap((instance) => from(instance.toBlob())));
    } else if (output === 'base64') {
      return resizor.pipe(map((instance) => instance.toDataURL()));
    } else {
      throw new Error('Invalid output type');
    }
  }
}
