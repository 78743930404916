import { AppSettingsBootstrapService } from './appsettingsbootstrap.service';
import { AuthService } from '../services/auth.service';
import { EnumValueDescriptorService } from './enumvalue-descriptor.service';
import { DateConversionService } from './date-conversion.service';
import { JavaScriptUtilsService } from './javascript-utils.service';
import { ScriptLoaderService } from './script-loader.service';
import { FileUtilsService } from './file-utils.service';
import { ConfirmationService } from './confirmation.service';
import { TokenHttpInterceptor } from './token-http-interceptor.service';
import { UserInfoService } from './user-info.service';
import { DialogService } from './dialog.service';

export const services: any[] = [
  AppSettingsBootstrapService,
  AuthService,
  EnumValueDescriptorService,
  DateConversionService,
  JavaScriptUtilsService,
  ScriptLoaderService,
  FileUtilsService,
  ConfirmationService,
  TokenHttpInterceptor,
  UserInfoService,
  DialogService,
];

export * from './appsettingsbootstrap.service';
export * from './auth.service';
export * from './enumvalue-descriptor.service';
export * from './date-conversion.service';
export * from './javascript-utils.service';
export * from './script-loader.service';
export * from './file-utils.service';
export * from './confirmation.service';
export * from './token-http-interceptor.service';
export * from './user-info.service';
export * from './dialog.service';
