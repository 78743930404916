import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MathService {
  // NOTE: As per John, modelling is accurate enough to the nearest integer.
  // TODO: Change the name to just Round and perhaps collapse the class.
  static RoundToInteger(n: number) {
    return Math.round(n);
  }

  static RoundToDecimal(n: number) {
    return Math.round(n * 10) / 10;
  }
}
