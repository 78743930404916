import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as fromEquipmentSummaries from '../actions/equipmentsummaries.actions';
import * as fromServices from 'src/app/app.generated';

export interface EquipmentSummariesState extends EntityState<fromServices.EquipmentSummaryVM> {
  hasLoaded: boolean;
  isLoading: boolean;
  loadError: any;
}

const adapter: EntityAdapter<fromServices.EquipmentSummaryVM> = createEntityAdapter<fromServices.EquipmentSummaryVM>();

export const intitalState: EquipmentSummariesState = adapter.getInitialState({
  hasLoaded: false,
  isLoading: false,
  loadError: null,
});

export function reducer(state = intitalState, action: fromEquipmentSummaries.EquipmentSummariesActions): EquipmentSummariesState {
  switch (action.type) {
    case fromEquipmentSummaries.EquipmentSummariesActionTypes.LoadEquipmentSummaries: {
      if (state === intitalState || state.loadError) {
        return {
          ...intitalState,
          isLoading: true,
        };
      } else {
        return state;
      }
    }

    case fromEquipmentSummaries.EquipmentSummariesActionTypes.LoadEquipmentSummariesSuccess: {
      return adapter.setAll(action.payload, {
        ...state,
        hasLoaded: true,
        isLoading: false,
        loadError: null
      });
    }

    case fromEquipmentSummaries.EquipmentSummariesActionTypes.LoadEquipmentSummariesFromStore: {
      return state;
    }

    case fromEquipmentSummaries.EquipmentSummariesActionTypes.LoadEquipmentSummariesFail: {
      return adapter.removeAll({
        ...state,
        hasLoaded: false,
        isLoading: false,
        loadError: action.payload
      });
    }

    case fromEquipmentSummaries.EquipmentSummariesActionTypes.ClearEquipmentSummaries: {
      return intitalState;
    }

    default: {
      return state;
    }
  }
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors();
