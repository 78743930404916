import { RouterEffects } from './router.effect';
import { EquipmentSummariesEffects } from './equipmentsummaries.effect';
import { ConfirmDialogEffects } from './confirmdialog.effect';
import { ToastEffects } from './toast.effect';
import { LoadingBarEffects } from './loading-bar.effect';
import { BrowserEffects } from './browser.effects';
import { CurrentUserEffects } from './currentuser.effect';
import { AppSettingsEffects } from './appsettings.effect';

export const effects: any[] = [
  RouterEffects,
  EquipmentSummariesEffects,
  ConfirmDialogEffects,
  ToastEffects,
  LoadingBarEffects,
  BrowserEffects,
  CurrentUserEffects,
  AppSettingsEffects,
];

export * from './router.effect';
export * from './equipmentsummaries.effect';
export * from './confirmdialog.effect';
export * from './toast.effect';
export * from './loading-bar.effect';
export * from './browser.effects';
export * from './currentuser.effect';
export * from './appsettings.effect';
