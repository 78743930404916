import { Input, Directive, OnDestroy, OnInit, ElementRef } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, filter, tap } from 'rxjs/operators';

import * as fromAppState from '../../state';
import * as fromAppServices from '../../services';
import * as fromGenerated from 'src/app/app.generated';

@Directive({
  selector: '[appEnableControlForRole]'
})
export class EnableControlForRoleDirective implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();
  private projectRole = fromGenerated.ProjectRoleType.AssessmentCoordinator;

  @Input()
  set appEnableControlForRole(projectRole: fromGenerated.ProjectRoleType) {
    this.projectRole = projectRole;
  }

  @Input()
  appEnableControlForRoleAdditionalConditions = true;

  constructor(
    private hostElement: ElementRef,
    private store: Store<fromAppState.AppState>
  ) {}

  ngOnInit() {
    this.store
      .pipe(
        takeUntil(this.unsubscribe$),
        select(fromAppState.getCurrentUser),
        filter(u => !!u),
        tap(u => this.enableForRole(u))
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private enableForRole(u: fromGenerated.UserVM) {
    const enabled =
      (fromAppServices.UserInfoService.isAdmin(u) || fromAppServices.UserInfoService.hasProjectRole(u, this.projectRole))
      && this.appEnableControlForRoleAdditionalConditions;
    this.hostElement.nativeElement.disabled = !enabled;
  }
}
