import { Action } from '@ngrx/store';
import * as fromModels from '../../models';

export enum ToastActionTypes {
  ShowToast = '[Toast] Show Toast',
}
export class ShowToast implements Action {
  readonly type = ToastActionTypes.ShowToast;
  constructor(
    public severity: fromModels.ToastSeverity,
    public title: string,
    public message: string,
  ) { }
}

export type ToastActions =
  | ShowToast
;
