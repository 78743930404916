import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, skip, takeUntil, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import * as fromAppStore from '../state';
import * as fromAppModels from '../models';

@Injectable()
export class ConfirmationService {
  static dispatchActionWithConfirmation(
    store: Store<any>,
    subscriptions$: Subject<{}>,
    action: any,
    cdCorrelationId: string,
    cdTitle: string,
    cdMessage: string,
    actionErrorSelector: any,
    errorToastMessage: string
  ) {
    subscriptions$.next();

    store.dispatch(new fromAppStore.ShowConfirmDialog(cdCorrelationId, cdTitle, cdMessage));

    if (action) {
      store.pipe(
        takeUntil(subscriptions$),
        select(fromAppStore.getConfirmDialogResultForCorrelationId(cdCorrelationId)),
        filter(result => result === fromAppModels.ConfirmDialogResult.OK),
        tap(() => store.dispatch(action))
      )
      .subscribe();
    }

    store.pipe(
      takeUntil(subscriptions$),
      select(actionErrorSelector),
      skip(1), // NOTE: Skip the first value as it will be the result of the previous action.
      filter(e => !!e),
      tap(() => store.dispatch(
          new fromAppStore.ShowToast(
            fromAppModels.ToastSeverity.Error,
            `Error`,
            errorToastMessage
          )
        )
      )
    )
    .subscribe();
  }
}
