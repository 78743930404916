import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as fromCurrentUser from '../actions/currentuser.actions';
import * as fromModels from '../../models';

export interface CurrentUserState extends EntityState<fromModels.UserVMEx> { }

const adapter: EntityAdapter<fromModels.UserVMEx> = createEntityAdapter<fromModels.UserVMEx>();

export const intitalState: CurrentUserState = adapter.getInitialState();

export function reducer(state = intitalState, action: fromCurrentUser.CurrentUserActions): CurrentUserState {
  switch (action.type) {
    case fromCurrentUser.CurrentUserActionTypes.LoadCurrentUser: {
      const entity = <fromModels.UserVMEx>{
        id: 1,
        hasLoaded: false,
        isLoading: true,
        loadError: null,
      };

      return adapter.upsertOne(entity, state);
    }

    case fromCurrentUser.CurrentUserActionTypes.LoadCurrentUserSuccess: {
      const entity = <fromModels.UserVMEx>{
        ...action.payload,
        id: 1,
        hasLoaded: true,
        isLoading: false,
        loadError: null,
      };

      return adapter.upsertOne(entity, state);
    }

    case fromCurrentUser.CurrentUserActionTypes.LoadCurrentUserFromStore: {
      const entity = <fromModels.UserVMEx>{
        ...state.entities[1],
        id: 1,
        hasLoaded: true,
        isLoading: false,
        loadError: null,
      };

      return adapter.upsertOne(entity, state);
    }

    case fromCurrentUser.CurrentUserActionTypes.LoadCurrentUserFail: {
      const entity = <fromModels.UserVMEx>{
        ...state.entities[1],
        id: 1,
        hasLoaded: false,
        isLoading: false,
        loadError: action.payload,
      };

      return adapter.upsertOne(entity, state);
    }

    default: {
      return state;
    }
  }
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors();
