import { Action } from '@ngrx/store';
import * as fromServices from 'src/app/app.generated';

export enum EquipmentSummariesActionTypes {
  ClearEquipmentSummaries = '[EquipmentSummaries Page] Clear EquipmentSummaries',
  LoadEquipmentSummaries = '[EquipmentSummaries Page] Load EquipmentSummaries',
  LoadEquipmentSummariesSuccess = '[EquipmentSummaries Page] Load EquipmentSummaries Success',
  LoadEquipmentSummariesFromStore = '[EquipmentSummaries Page] Load EquipmentSummaries From Store',
  LoadEquipmentSummariesFail = '[EquipmentSummaries Page] Load EquipmentSummaries Fail',
  UpsertEquipmentSummary = '[EquipmentSummaries Page] Upsert EquipmentSummary',
  UpsertEquipmentSummaryFail = '[EquipmentSummaries Page] Upsert EquipmentSummary Fail',
  DeleteEquipmentSummary = '[EquipmentSummaries Page] Delete EquipmentSummary',
  DeleteEquipmentSummaryFail = '[EquipmentSummaries Page] Delete EquipmentSummary Fail',
}
export class ClearEquipmentSummaries implements Action {
  readonly type = EquipmentSummariesActionTypes.ClearEquipmentSummaries;
}
export class LoadEquipmentSummaries implements Action {
  readonly type = EquipmentSummariesActionTypes.LoadEquipmentSummaries;
}
export class LoadEquipmentSummariesSuccess implements Action {
  readonly type = EquipmentSummariesActionTypes.LoadEquipmentSummariesSuccess;
  constructor(public payload: fromServices.EquipmentSummaryVM[]) {}
}
export class LoadEquipmentSummariesFromStore implements Action {
  readonly type = EquipmentSummariesActionTypes.LoadEquipmentSummariesFromStore;
}
export class LoadEquipmentSummariesFail implements Action {
  readonly type = EquipmentSummariesActionTypes.LoadEquipmentSummariesFail;
  constructor(public payload: any) {}
}
export class UpsertEquipmentSummary implements Action {
  readonly type = EquipmentSummariesActionTypes.UpsertEquipmentSummary;
  constructor(public equipmentSummary: fromServices.EquipmentSummaryVM) { }
}
export class UpsertEquipmentSummaryFail implements Action {
  readonly type = EquipmentSummariesActionTypes.UpsertEquipmentSummaryFail;
  constructor(public payload: any) { }
}
export class DeleteEquipmentSummary implements Action {
  readonly type = EquipmentSummariesActionTypes.DeleteEquipmentSummary;
  constructor(public equipmentId: number) { }
}
export class DeleteEquipmentSummaryFail implements Action {
  readonly type = EquipmentSummariesActionTypes.DeleteEquipmentSummaryFail;
  constructor(public payload: any) { }
}

export type EquipmentSummariesActions =
  | ClearEquipmentSummaries
  | LoadEquipmentSummaries
  | LoadEquipmentSummariesSuccess
  | LoadEquipmentSummariesFromStore
  | LoadEquipmentSummariesFail
  | UpsertEquipmentSummary
  | UpsertEquipmentSummaryFail
  | DeleteEquipmentSummary
  | DeleteEquipmentSummaryFail;
