import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, withLatestFrom, concatMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import * as fromGenerated from '../../app.generated';
import * as fromStore from '..';
import * as fromActions from '../actions/currentuser.actions';
import * as currentUserSelectors from '../selectors/currentuser.selectors';

@Injectable()
export class CurrentUserEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromStore.AppState>,
    private service: fromGenerated.CurrentUserService,
  ) {}

  loadCurrentUser$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.CurrentUserActionTypes.LoadCurrentUser),
    withLatestFrom(this.store.pipe(select(currentUserSelectors.getCurrentUserHasLoaded))),
    mergeMap(([ _, hasLoaded ]): Observable<fromActions.CurrentUserActions> => {
      if (hasLoaded) {
        return of(new fromActions.LoadCurrentUserFromStore());
      } else {
        return of(new fromActions.ReloadCurrentUser());
      }
    })
  ));

  reloadCurrentUser$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.CurrentUserActionTypes.ReloadCurrentUser),
    mergeMap((): Observable<fromActions.CurrentUserActions> => {
      return this.service.get().pipe(
        map((grs: fromGenerated.UserVM) => new fromActions.LoadCurrentUserSuccess(grs)),
        catchError(error => of(new fromActions.LoadCurrentUserFail(error)))
      );
    })
  ));
}
